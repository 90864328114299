/**
 * 是否是移动端
 * @returns bool 
 */
export function IsMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return !!flag;

}

/**
 * 样式兼容性
 * @returns hack 
 */
export function prefix() {

    const transformNames = {

        webkit: 'webkitTransform',

        Moz: 'MozTransform',

        O: 'OTransform',

        ms: 'msTransform',

        standard: 'transform'
    }

    for (const key in transformNames) {

        if (document.body.style[transformNames[key]] !== undefined) {

            return "-" + key + "-"

        }
    }
    return false
}

/** 点和矩形碰撞      
 * @param x1 点 
 * @param y1 点 
 * @param x2 矩形view x 
 * @param y2 矩形view y 
 * @param w  矩形view 宽 
 * @param h  矩形view 高 
 * @return 
 */
export function isCollision(x1, y1, x2, y2, w, h) {
    if (x1 >= x2 && x1 <= x2 + w && y1 >= y2 && y1 <= y2 + h) {
        return true;
    }
    return false;
}


//定义函数
/**
 * 绑定事件的函数（处理兼容性）
 * @param {dom} obj 
 * @param {*} eventName
 * @param {*} callback 
 */
export function bindEvent(obj, eventName, callback) {
    if (obj.addEventListener) {//判断是否含有addEventListener
        obj.addEventListener(eventName, callback, false);
    } else {
        //obj.attachEvent("on" + eventStr, callback);//callback是浏览器调用回到函数，我们希望把调用回调函数的权利拿回来
        //ie8及以下的方法不支持则使用 attachEvent();

        obj.attachEvent("on" + eventName, function () {
            //在匿名函数中调用回调函数,这样回调函数改成我们自己调用的了
            callback.call(obj);//修改this为obj，这样这个bind函数就统一了this

        });
    }
}





export function eventClick(name) {
    _hmt.push(["_trackEvent", "page", "click", name]);
}
