var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"galleryWrap"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('jump',{staticClass:"jumpLeftTop"}),_c('jump',{staticClass:"jumpLeftBottom"}),_c('jump',{staticClass:"jumpRightTop",attrs:{"isRight":true}}),_c('div',{staticClass:"contentBox"},[_c('div',{staticClass:"galleryTop"},[_vm._m(4),_c('div',{staticClass:"galleryBack",on:{"click":_vm.galleryBack}},[_c('img',{attrs:{"src":require("../assets/images/gallery/galleryBack.png"),"alt":""}})])]),_c('div',{staticClass:"scrollWrap",attrs:{"id":"scrollWrap"},on:{"wheel":_vm.scroll}},[_c('div',{staticClass:"galleryContent",attrs:{"id":"scrollContent"}},_vm._l((_vm.roleList),function(item,index){return _c('div',{key:index,staticClass:"galleryItem",style:({ 'animation-delay': `${index * 50}ms` }),on:{"click":function($event){return _vm.itemClick(item.id)}}},[_c('div',{staticClass:"itemImg"},[_c('img',{attrs:{"src":item.coverPic,"alt":""}}),_c('div',{staticClass:"roleName"},[_vm._v(_vm._s(item.roleName))])]),_c('div',{staticClass:"county"},[_vm._v(_vm._s(item.intro))])])}),0),_vm._m(5)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_c('img',{attrs:{"src":require("../assets/images/gallery/galleryBg.jpg"),"alt":""}}),_c('div',{staticClass:"bgPoint"},[_c('img',{attrs:{"src":require("../assets/images/gallery/point.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pcBg"},[_c('div',{staticClass:"bgPoint"},[_c('img',{attrs:{"src":require("../assets/images/gallery/galleryBgPcHavePoint.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgText"},[_c('img',{attrs:{"src":require("../assets/images/gallery/bgText.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgTextRight"},[_c('img',{attrs:{"src":require("../assets/images/gallery/bgText.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"galleryTitle"},[_c('img',{attrs:{"src":require("../assets/images/gallery/galleryTitle.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scrollBar",attrs:{"id":"scrollBar"}},[_c('div',{staticClass:"box",attrs:{"id":"box"}},[_c('img',{attrs:{"src":require("../assets/images/gallery/barBox.png"),"alt":""}})])])
}]

export { render, staticRenderFns }