<template>
  <div class="galleryWrap">
    <div class="bg">
      <img src="../assets/images/gallery/galleryBg.jpg" alt="" />
      <div class="bgPoint">
        <img src="../assets/images/gallery/point.png" alt="" />
      </div>
    </div>
    <div class="pcBg">
      <div class="bgPoint">
        <img src="../assets/images/gallery/galleryBgPcHavePoint.jpg" alt="" />
      </div>
    </div>
    <div class="bgText">
      <img src="../assets/images/gallery/bgText.png" alt="" />
    </div>
    <div class="bgTextRight">
      <img src="../assets/images/gallery/bgText.png" alt="" />
    </div>
    <jump class="jumpLeftTop"></jump>
    <jump class="jumpLeftBottom"></jump>
    <jump class="jumpRightTop" :isRight="true"></jump>
    <div class="contentBox">
      <div class="galleryTop">
        <div class="galleryTitle">
          <img src="../assets/images/gallery/galleryTitle.jpg" alt="" />
        </div>
        <div class="galleryBack" @click="galleryBack">
          <img src="../assets/images/gallery/galleryBack.png" alt="" />
        </div>
      </div>
      <div class="scrollWrap" @wheel="scroll" id="scrollWrap">
        <div class="galleryContent" id="scrollContent">
          <div class="galleryItem" v-for="(item, index) in roleList" :key="index" :style="{ 'animation-delay': `${index * 50}ms` }" @click="itemClick(item.id)">
            <div class="itemImg">
              <img :src="item.coverPic" alt="" />
              <div class="roleName">{{ item.roleName }}</div>
            </div>
            <div class="county">{{ item.intro }}</div>
          </div>
        </div>
        <div class="scrollBar" id="scrollBar">
          <div class="box" id="box">
            <img src="../assets/images/gallery/barBox.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jump from "./jump.vue";
import { Toast } from "vant";

import request from "@/util/request";
export default {
  name: "Gallery",
  components: { jump },
  data() {
    return {
      roleList: [], // 角色列表
      a: 0,
      x: 0,
      speed: 90,
    };
  },
  methods: {
    galleryBack() {
      this.$emit("galleryBack");
    },
    itemClick(val) {
      this.$emit("clickPaint", val);
    },

    // pc 滚动
    scroll(e) {
      let scrollContent = document.querySelector(".galleryContent");
      let contentHeight = document.getElementById("scrollContent").offsetHeight;
      let viewHeight = document.getElementById("scrollWrap").offsetHeight;
      let barHeight = document.getElementById("scrollBar").offsetHeight;
      let boxHeight = document.getElementById("box").offsetHeight;
      let box = document.querySelector("#box");
      if (e.deltaY > 0) {
        this.a += this.speed;
        if (this.a >= contentHeight - viewHeight) {
          this.a = contentHeight - viewHeight;
        }
        let speedB = this.a / (contentHeight - viewHeight);
        this.x = speedB * barHeight - boxHeight;
        scrollContent.style.transform = `translate(0,-${this.a}px)`;
        box.style.transform = ` translate(-50%,${this.x}px)`;
      } else {
        this.a -= this.speed;
        if (this.a <= 0) {
          this.a = 0;
        }
        let speedB = this.a / (contentHeight - viewHeight);
        this.x = speedB * barHeight;
        scrollContent.style.transform = `translate(0,-${this.a}px)`;
        box.style.transform = ` translate(-50%,${this.x}px) `;
      }
    },
  },
  mounted() {
    //请求角色列表
    request({ url: "roleList" })
      .then((e) => {
        this.roleList = e.data;
        // for (let i = 0; i <= 1; i++) {
        //   this.roleList = this.roleList.concat(this.roleList);
        //   console.log(this.roleList);
        // }
        Toast.clear();
        // // 判断是否出滚动条
        // let contentHeight =
        //   document.getElementById("scrollContent").offsetHeight;
        // let viewHeight = document.getElementById("scrollWrap").offsetHeight;
        // if (contentHeight - viewHeight <= 0) {
        //   let scrollBar = document.getElementById("scrollBar");
        //   scrollBar.style.display = "none";
        // }
      })
      .catch((err) => {
        Toast.clear();
        Toast.fail({
          message: "【エラー】",
        });
      });
  },
};
</script>
<style lang="less" scoped>
@import "../assets/css/public.less";
.galleryWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e5e5e5;
  .bg {
    width: 100%;
    position: relative;
    .bgPoint {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      animation: opacity0to1 1000ms 200ms ease infinite alternate;
    }
  }
  .pcBg {
    display: none;
  }
  .bgText {
    .attr_val(width,62);
    position: absolute;
    top: 45%;
    left: 0%;
    animation: bgText 7000ms linear infinite;
  }
  .jumpLeftTop {
    position: absolute;
    top: 5%;
    left: -0.5%;
  }
  .jumpLeftBottom {
    position: absolute;
    bottom: 5%;
    left: -0.5%;
  }
  .jumpRightTop {
    position: absolute;
    top: 5%;
    right: -0.5%;
  }
  .contentBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .galleryTop {
      .attr_val(margin-top,150);
      display: flex;
      justify-content: space-between;

      .galleryTitle {
        .attr_val(width,192);

        .attr_val(margin-left,32);
      }
      .galleryBack {
        .attr_val(width,235);
        .attr_val(margin-top,14);
      }
    }
    .galleryContent {
      width: 100%;

      box-sizing: border-box;
      padding: 0 4vw;
      display: flex;
      flex-wrap: wrap;

      .galleryItem {
        .attr_val(margin-bottom,20);
        .attr_val(width,334);
        background-color: #fff;
        transform: translate(0, 20%);
        opacity: 0;
        animation: elementRun 500ms ease both;
        .itemImg {
          position: relative;
          width: 100%;
          .attr_val(height,225);
          overflow: hidden;
          .roleName {
            .attr_val(width,140);
            .attr_val(height,30);
            background-color: #5ebcff;
            .attr_val(border-top-right-radius,30);
            position: absolute;
            bottom: 0%;
            left: 0%;
            .attr_val(font-size,14);
            .attr_val(line-height,30);
            text-align: center;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: "zt";
          }
        }
        .county {
          text-indent: 1em;
          font-weight: 300;
          .attr_val(font-size,14);
          .attr_val(line-height,28);
          .attr_val(height,28);
          text-align: left;
          width: 100%;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-family: "zt";
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .galleryWrap .contentBox .galleryContent {
    max-height: 76.6vh;
    overflow: hidden;
    overflow-y: scroll;
    .attr_val(margin-top,20);
    justify-content: space-between;
  }
  .scrollBar {
    display: none;
  }
}
@media (min-width: 1000px) {
  .jumpLeftTop,
  .jumpLeftBottom,
  .jumpRightTop {
    display: none;
  }
  .galleryWrap {
    background-color: #e5e5e5;
    .bg {
      display: none;
    }
    .pcBg {
      display: block !important;
      .bgPoint {
        animation: opacity0to1 1000ms 200ms ease infinite alternate;
      }
    }
    .bgText {
      .p_attr_val(width,68);
      animation: bgText 7000ms linear infinite;
    }

    .bgTextRight {
      position: absolute;
      top: 45%;
      right: 0%;
      .p_attr_val(width,188);
      animation: bgTextRight 7000ms linear infinite;
      transform: scaleX(-1);
    }
    .contentBox {
      .galleryTop {
        .p_attr_val(margin-top,50);
        .p_attr_val(padding-left,150);
        .p_attr_val(padding-right,150);

        .galleryTitle {
          .p_attr_val(width,192);

          .p_attr_val(margin-left,32);
        }
        .galleryBack {
          cursor: pointer;
          .p_attr_val(width,235);
          .p_attr_val(margin-top,14);
        }
      }
      .scrollWrap {
        margin: 0 auto;
        .p_attr_val(margin-top,40);
        .p_attr_val(width,1599);
        max-height: 83vh;
        overflow: hidden;
        position: relative;
        .galleryContent {
          overflow: hidden;
          transition: all ease 0.5s;
          .galleryItem {
            // .p_attr_val(width,334);
            .p_attr_val(margin-bottom,20);
            margin-left: 1%;
            cursor: pointer;
            width: 24%;
            position: relative;
            .itemImg {
              width: 100%;
              height: auto;
              .roleName {
                font-family: "zt";
                .p_attr_val(font-size,15);
                .p_attr_val(line-height,28);
                .p_attr_val(height,28);
                .p_attr_val(width,130);
                .p_attr_val(border-top-right-radius,30);
              }
            }
            .county {
              text-indent: 1em;
              .p_attr_val(font-size,14);
              .p_attr_val(line-height,25);
              .p_attr_val(height,25);
              font-family: "zt";
            }
          }
        }
        .scrollBar {
          display: block;
          position: absolute;
          top: 0%;
          left: 1.5%;
          width: 3px;
          height: 100%;
          background-color: #484848;
          .box {
            transition: all ease 0.5s;
            position: absolute;
            left: 50%;
            // top: 1.5%;
            // .p_attr_val(width,14);
            // .p_attr_val(height,14);
            height: 23px;
            width: 23px;
            transform: translate(-50%, 0);
          }
        }
      }
    }
  }
}
</style>