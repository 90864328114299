<template>
  <div class="Wrap">
    <div class="ismb" v-if="isMobile">
      <div class="main">
        <div class="bg" v-if="backColor==1">
          <img class="mbg" src="../assets/images/blackBg.jpg" alt="" />
          <img class="pointBg" src="../assets/images/blackBgHavePoint.jpg" alt="" />
        </div>
        <div class="bg" v-if="backColor==2">
          <img class="mbg" src="../assets/images/greyBg.jpg" alt="" />
          <img class="pointBg" src="../assets/images/greyBgHavePoint.jpg" alt="" />
        </div>
        <div class="bg" v-if="backColor==3">
          <img class="mbg" src="../assets/images/whiteBg.jpg" alt="" />
          <img class="pointBg" src="../assets/images/whiteBgHavePoint.jpg" alt="" />
        </div>
        <div class="contentBox" id="contentBox">
          <div class="logo">
            <img v-if="backColor==3" src="../assets/images/blackLogo.png" alt="" />
            <img v-else src="../assets/images/whiteLogo.png" alt="" />
          </div>
          <div class="mapContent" :style="{'background-color':backColor==3?'#eeeeee':''}">
            <div class="mapTitle">
              <img v-if="backColor==3" src="../assets/images/mapTitleWhite.png" alt="" />
              <img v-else src="../assets/images/mapTitle.png" alt="" />
              <div class="titleIcon">
                <img src="../assets/images/titleIcon.png" alt="">
                <a class="titleLink" href="https://twitter.com/NIKKE_japan/status/1580093659474640896" target="_blank"></a>
              </div>
              <div class="titleBottom"><img src="../assets/images/titleBottom.png" alt=""></div>
            </div>
            <div class="RTBox">
              <div class="rt">
                <img v-if="backColor==3" src="../assets/images/RTWhite.png" alt="" />
                <img v-else src="../assets/images/RT.png" alt="" />
                <div class="rtNum" v-if="homeShareNum">{{homeShareNum}}</div>
                <div class="rtNum" v-else>{{mapInfo.shareNum}}</div>
                <!-- <scroll-number class="rtNum" :value="mapInfo.shareNum"></scroll-number> -->
              </div>
              <div class="likeBox" @click="homeLikeClick">
                <div class="like">
                  <img v-if="backColor==3&&!isHomeLike" src="../assets/images/likeWhite.png" alt="" />
                  <img v-if="backColor!=3&&!isHomeLike" src="../assets/images/like.png" alt="" />
                  <img v-if="isHomeLike" class="likeBright" src="../assets/images/viewPaint/likeBright.png" alt="" />
                </div>
                <!-- <scroll-number class="likeNum" :value="mapInfo.likeNum" :style="{'color':backColor==3?'black':''}"></scroll-number> -->
                <div class="likeNum" v-if="homeLikeNum" :style="{'color':backColor==3?'black':''}">{{homeLikeNum}}</div>
                <div class="likeNum" v-else :style="{'color':backColor==3?'black':''}">{{mapInfo.likeNum}}</div>

              </div>
            </div>
            <div class="map">
              <Map :mapInfo="mapInfo" @click-thumbtack="mapClick" @complateScale="complateScale"></Map>
            </div>
            <div class="btnBox">
              <div class="icon" @click="toTwClick">
                <img v-if="backColor==3" src="../assets/images/iconWhite.png" alt="" />
                <img v-if="backColor!=3" src="../assets/images/icon.png" alt="" />
                <img v-if="backColor==3" class="iconSon bird" src="../assets/images/iconaWhite.png" alt="" />
                <img v-if="backColor!=3" class="iconSon bird" src="../assets/images/icona.png" alt="" />
              </div>
              <div class="icon" @click="toDis">
                <img v-if="backColor==3" src="../assets/images/iconWhite.png" alt="" />
                <img v-if="backColor!=3" src="../assets/images/icon.png" alt="" />
                <img v-if="backColor==3" class="iconSon girl" src="../assets/images/iconbWhite.png" alt="" />
                <img v-if="backColor!=3" class="iconSon girl" src="../assets/images/iconb.png" alt="" />
              </div>
              <div class="loginBtn" style="position: relative" @click="loginBtnClick">
                <img src="../assets/images/loginBtn.png" alt="" />
                <div class="loginBtnText"></div>
                <div class="loginBtnTriangle" v-if="backColor!=3"></div>
              </div>
              <div class="icon" @click="galleryClick">
                <img v-if="backColor==3" src="../assets/images/iconWhite.png" alt="" />
                <img v-if="backColor!=3" src="../assets/images/icon.png" alt="" />
                <div class="albumBox">
                  <img v-if="backColor==3" class="album" src="../assets/images/iconcWhite1.png" alt="" />
                  <img v-if="backColor!=3" class="album" src="../assets/images/iconc.png" alt="" />
                </div>

                <div class="iconcBottom">
                  <img src="../assets/images/iconcBottom.png" alt="" />
                </div>
              </div>
              <div class="icon" @click="shareRT">
                <img v-if="backColor==3" src="../assets/images/iconWhite.png" alt="" />
                <img v-if="backColor!=3" src="../assets/images/icon.png" alt="" />

                <img v-if="backColor==3" class="iconSon" src="../assets/images/icondTextWhite.png" alt="" />
                <img v-if="backColor!=3" class="iconSon" src="../assets/images/icondText.png" alt="" />
                <div class="shareBox">
                  <img v-if="backColor==3" class="shareIcon" src="../assets/images/icondShareWhite.png" alt="" />
                  <img v-if="backColor!=3" class="shareIcon" src="../assets/images/icondShare.png" alt="" />
                </div>

                <div class="icondBottom">
                  <img src="../assets/images/icondBottom.png" alt="" />
                </div>
              </div>
            </div>

            <div class="award">
              <div class="Rtbox">
                <div class="itemRT" v-for="i in 3" :key="i" :class="'itemRT'+i" :style="{'color':backColor==3?'black':''}">
                  シェア:{{1000+(i-1)*500 }}
                </div>
              </div>
              <div class="progressBar">
                <div class="barBox"></div>
                <div class="pillarBox">
                  <div class="pillar" :class="'pillar'+i" v-for="i in 3" :key="i">
                    <img v-if="i>awardCurrent" src="../assets/images/pillar.png" alt="" />
                    <img v-else src="../assets/images/pillarAction.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="awardImgBox">
                <div class="awardImg" v-for="i in 3" :key="i" :class="'awardImg'+i">

                  <img v-if="i>awardCurrent" :src="require('../assets/images/lockaward'+i+'.png')" alt="">
                  <img v-else :src="require('../assets/images/award'+i+'.png')" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="note">
            <img v-if="backColor==3" src="../assets/images/noteWhite.png" alt="" />
            <img v-else src="../assets/images/note.png" alt="" />
          </div>
          <div class="foot">
            <a class="noteBottom"  :style="{'color':backColor==3?'black':''}" href="https://nikke-jp.com/pre/privacypolicy/" target="_balnk">利用規約</a>
          </div>
        </div>
      </div>
    </div>
    <div class="ispc" v-if="!isMobile">
      <div class="pcMain">
        <div class="pcBg">
          <img v-if="backColor==1" class="pBg" src="../assets/images/blackBgPc.jpg" alt="" />
          <img v-if="backColor==2" class="pBg" src="../assets/images/Bg2Pc.jpg" alt="" />
          <img v-if="backColor==3" class="pBg" src="../assets/images/Bg3Pc.jpg" alt="" />
          <img v-if="backColor==1" class="pointBg" src="../assets/images/blackBgPcHavePoint.jpg" alt="" />
          <img v-if="backColor==2" class="pointBg" src="../assets/images/Bg2PcHavePoint.jpg" alt="" />
          <img v-if="backColor==3" class="pointBg" src="../assets/images/Bg3PcHavePoint.jpg" alt="" />
          <div class="bgText">
            <img v-if="backColor==3" src="../assets/images/bgTextLeftWhite.png" alt="" />
            <img v-else src="../assets/images/blackBgTextLeft.png" alt="" />
          </div>
          <div class="bgTextRight">
            <img v-if="backColor==3" src="../assets/images/bgTextRightWhite.png" alt="" />
            <img v-else src="../assets/images/blackBgTextRight.png" alt="" />
          </div>
          <jump class="pcBgJumpTop1"></jump>
          <jump class="pcBgJumpTop"></jump>
        </div>
        <div class="contentBox">
          <div class="logo">
            <img v-if="backColor==3" src="../assets/images/blackLogo.png" alt="" />
            <img v-else src="../assets/images/whiteLogo.png" alt="" />
          </div>
          <div class="mapContent" :style="{'background-color':backColor==3?'rgba(255,255,255,.6)':''}">
            <div class="mapTitlePc">
              <img v-if="backColor==3" src="../assets/images/mapTitlePcWhite.png" alt="" />
              <img v-else src="../assets/images/mapTitlePc.png" alt="" />
              <div class="titleIcon">
                <img src="../assets/images/titleIcon.png" alt="">
                <a class="titleLink" href="https://twitter.com/NIKKE_japan/status/1580093659474640896" target="_blank"></a>
              </div>
              <div class="titleBottom"><img src="../assets/images/titleBottom.png" alt=""></div>
            </div>
            <div class="map">
              <Map :mapInfo="mapInfo" @click-thumbtack="mapClick"></Map>
            </div>

            <div class="pcBtnBox">
              <div class="pcBtnIcon" @click="toTwClick">
                <img v-if="backColor==3" src="../assets/images/pcIconaWhite.png" alt="" />
                <img v-else src="../assets/images/pcIcona.png" alt="" />
                <img v-if="backColor==3" class="iconSon bird" src="../assets/images/iconaWhite.png" alt="" />
                <img v-if="backColor!=3" class="iconSon bird" src="../assets/images/icona.png" alt="" />
              </div>
              <div class="pcBtnIcon" @click="shareRT">
                <img v-if="backColor==3" src="../assets/images/pcIcondWhite.png" alt="" />
                <img v-else src="../assets/images/pcIcond.png" alt="" />
                <!-- <img v-if="backColor==3" class="icondTextRt" src="../assets/images/icondTextWhite.png" alt="" />
                <img v-if="backColor!=3" class="icondTextRt" src="../assets/images/icondText.png" alt="" /> -->
                <div class="shareBox">
                  <img v-if="backColor==3" class="shareIcon" src="../assets/images/icondShareWhite.png" alt="" />
                  <img v-if="backColor!=3" class="shareIcon" src="../assets/images/icondShare.png" alt="" />
                </div>
              </div>
              <div class="pcBtnIcon" @click="toDis">
                <img v-if="backColor==3" src="../assets/images/pcIconbWhite.png" alt="" />
                <img v-else src="../assets/images/pcIconb.png" alt="" />
                <img v-if="backColor==3" class="iconSon girl" src="../assets/images/iconbWhite.png" alt="" />
                <img v-if="backColor!=3" class="iconSon girl" src="../assets/images/iconb.png" alt="" />
              </div>
            </div>
            <div class="RTBox">
              <div class="rt">
                <img v-if="backColor==3" src="../assets/images/RTWhite.png" alt="" />
                <img v-else src="../assets/images/RT.png" alt="" />
                <!-- <scroll-number class="rtNum" :value="mapInfo.shareNum"></scroll-number> -->
                <div class="rtNum" v-if="homeShareNum">{{homeShareNum}}</div>
                <div class="rtNum" v-else>{{mapInfo.shareNum}}</div>
              </div>
              <div class="likeBox" @click="homeLikeClick">
                <div class="like">
                  <img v-if="backColor==3&&!isHomeLike" src="../assets/images/likeWhite.png" alt="" />
                  <img v-if="backColor!=3&&!isHomeLike" src="../assets/images/like.png" alt="" />
                  <img v-if="isHomeLike" class="likeBright" src="../assets/images/viewPaint/likeBright.png" alt="" />

                </div>
                <!-- <scroll-number class="likeNum" :value="mapInfo.likeNum" :style="{'color':backColor==3?'black':''}"></scroll-number> -->
              <div class="likeNum" v-if="homeLikeNum" :style="{'color':backColor==3?'black':''}">{{homeLikeNum}}</div>
                <div class="likeNum" v-else :style="{'color':backColor==3?'black':''}">{{mapInfo.likeNum}}</div>
              </div>
              <div class="pcBtnIcon" @click="galleryClick">
                <img src="../assets/images/pcIconc.png" alt="" />
                <div class="loginBtnTriangle" v-if="backColor!=3"></div>
                <div class="albumBox">
                  <img class="album" src="../assets/images/iconcWhite.png" alt="" />
                </div>
                <div class="pcIconcBottom">
                  <img src="../assets/images/pcIconcBottom.png" alt="" />
                </div>
              </div>
            </div>
            <div class="awardPc">
              <div class="item" v-for="i in 3" :key="i" :style="`order:${3 - i}`">
                <div class="Rt" :style="{'color':backColor==3?'black':''}">シェア: {{ 1000+(i-1)*500 }}</div>
                <div class="awardImgBox">
                  <img class="awardImg" v-if="i >awardCurrent" :src="require('../assets/images/lockaward'+i+'.png')" alt="">
                  <img class="awardImg" v-else :src="require('../assets/images/award'+i+'.png')" alt="">
                  <div class="pillar">
                    <img v-if="i>awardCurrent" src="../assets/images/pillarPc.png" alt="" />
                    <img v-else src="../assets/images/pillarActionPc.png" alt="" />
                  </div>
                </div>

              </div>
              <div class="preBar">
                <div class="barBox">
                  <div class="pre"></div>
                  <div class="bottomHeight"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="loginBtn" style="position: relative" @click="loginBtnClick">
            <img src="../assets/images/loginBtn.png" alt="" />
            <div class="loginBtnTriangle" v-if="backColor!=3"></div>

            <div class="loginBtnText"></div>
          </div>
          <div class="note">
            <img v-if="backColor==3" src="../assets/images/noteWhite.png" alt="" />
            <img v-else src="../assets/images/note.png" alt="" />
          </div>
          <div class="foot" >
            <a class="noteBottom" :style="{'color':backColor==3?'black':''}" href="https://nikke-jp.com/pre/privacypolicy/" target="_balnk">利用規約</a>
          </div>
        </div>
      </div>
    </div>
    <gallery v-if="isGalleryShow" @galleryBack="galleryBack" @clickPaint="clickPaint"></gallery>

    <view-painting v-show="currentId" @closePaint="closePaint" :roleInfo="roleInfo"></view-painting>
    <div class="cookieBox" v-if="isCookie">
      <div class="cookie">
        <div class="text">
          当社では、本ウェブサイトを操作し、お客様にさまざまな機能を提供し、Cookieポリシーに従って本ウェブサイトのパフォーマンスを分析・改善するために、Cookieを使用しています。本ウェブサイトをご利用いただく際には、お使いのデバイスにCookieを保存することに同意いただいたものとみなします。<a style=" text-decoration: underline;" href=" https://nikke-jp.com/cookiepolicy/"
            target="_blank">Cookieポリシー</a>
        </div>
        <div class="cookieBtn" @click="cookieClick">すべて承諾</div>
        <div class="close" @click="isCookie=false"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Gallery from "@/components/Gallery.vue";
import ViewPainting from "@/components/ViewPainting.vue";
import Map from "../component/map/map.vue";
import jump from "../components/jump.vue";
import ScrollNumber from "../components/ScrollNumber.vue";

import { Toast } from "vant";
import request from "../util/request";
import { IsMobile } from "@/util/util";
import { eventClick } from "@/util/util";

export default {
  name: "Home",
  components: { Gallery, ViewPainting, Map, jump, ScrollNumber },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      isMobile: IsMobile(),
      isGalleryShow: false, //画廊是否显示
      currentId: "", //被点的图id
      roleInfo: {}, // 被点的图信息
      mapInfo: {},
      awardCurrent: "", //解锁到第几个
      backColor: 1, //  背景解锁 1 未解锁黑  2 解锁中灰 3 解锁白
      isCookie: false, // 控制cookie显示
      isHomeLike:false,  //主页点赞 
      homeShareNum:"",
      homeLikeNum:"", 
    };
  },
  methods: {
    // 主页点赞
    homeLikeClick(){
      if(this.isHomeLike){
        return
      }
        this.isHomeLike = true
        request({
          url:'submitHomeShare',
          params: {type: 0,},
        }).then(res=>{
        eventClick("主页点赞")
        this.homeLikeNum = res.data.likeNum
        console.log('dianzan')
        })
    },
    //打开画廊
    galleryClick() {
      Toast.loading({
        // 加载中
        message: "【ローディング】",
        forbidClick: true,
        duration: 0,
      });
      this.isGalleryShow = true;
      eventClick("打开画廊");
    },
    loginBtnClick() {
      eventClick("跳官网");
      setTimeout(() => {
        window.open("https://nikke-jp.com/pre/");
      }, 50);
    },
    toTwClick() {
      eventClick("跳推特");
      setTimeout(() => {
        window.open("https://twitter.com/NIKKE_japan");
      }, 50);
    },
    toDis() {
      eventClick("跳discord");
      setTimeout(() => {
        window.open("https://discord.gg/nikke-jp");
      }, 50);
    },
    cookieClick() {
      localStorage.isCookie = 1;
      this.isCookie = false;
    },
    galleryBack() {
      this.isGalleryShow = false;
    },
    // 画廊点击打开立绘
    clickPaint(val) {
      this.currentId = val;
      request({
        url: "roleDeatil",
        method: "get",
        params: {
          id: val,
        },
      }).then((e) => {
        if (!e.data) {
          this.currentId = "";
          Toast.fail({
            message: "暂无图片",
          });
          return;
        }
        this.roleInfo = e.data;
        this.roleInfo.id = val;
      });
    },
    //关闭预览立绘
    closePaint() {
      this.currentId = "";
      this.roleInfo = {};
    },
    mapClick(val) {
      console.log(val);
      this.clickPaint(val);
    },
    scaleContent() {
      if (!this.screenWidth) {
        return;
      }
      let contentBox = document.getElementById("contentBox");
      let bodyHeight = document.body.clientHeight;
      if (contentBox.offsetHeight > bodyHeight) {
        let scale = bodyHeight / contentBox.offsetHeight;
        contentBox.style.transform = `translate(-50%, -50%) scale(${scale})`;
      }
    },
    complateScale() {
      this.scaleContent();
    },
    // 设置进度条
    getProgressBar(val) {
      var a = 0;
      // 手机端
      if (this.isMobile) {
        if (val <= 1000) {
          a = (val / 1000) * 0.2;
        } else if (val > 1000 && val <= 2000) {
          // 中间刻度
          a = 0.2 + ((val - 1000) / 1000) * 0.6;
        } else if (val > 2000 && val < 3000) {
          a = 0.8 + ((val - 2000) / 1000) * 0.2;
        } else {
          a = 1;
        }
        console.log(a, "a");
        let barBox = document.querySelector(".barBox");
        barBox.style.width = `${a * 100}%`;
      }
      // pc
      if (!this.isMobile) {
        a = val / 2000;
        let pre = document.querySelector(".pre");
        let bottomHeight = document.querySelector(".bottomHeight");
        pre.style.height = `${(a / 3) * 200}%`;
        bottomHeight.style.height = "33.33333%";
      }
      // 设置解锁到几
      switch (true) {
        case val < 1000:
          this.awardCurrent = 0;
          break;
        case val >= 1000 && val < 1500:
          this.awardCurrent = 1;
          break;
        case val >= 1500 && val < 2000:
          this.awardCurrent = 2;
          break;
        case val >= 2000:
          this.awardCurrent = 3;
          break;
      }
    },
    // 推特分享
    shareRT() {
      eventClick("分享到推特")
      request({
          url:'submitHomeShare',
          params: {type: 1},
        }).then((res)=>{
          this.homeShareNum = res.data.shareNum
        })
      let text = `【＃NIKKE事前登録受付中】47都道府県に ＃NIKKE たちが上陸中！？どのNIKKEがあなたの住んでいるエリアへやってくるか、ぜひCHECKしよう！
＃ニケ日本上陸プロジェクト`;
      let link = "https://www.nikke-47campaign.com/index.html?/v5"; // 分享的页面
      // let link = location.href; // 分享的页面
      setTimeout(() => {
        window.open(
          "https://twitter.com/intent/tweet?url=" +
            encodeURIComponent(link) +
            "&text=" +
            encodeURIComponent(text)
        );
      }, 50);
    },
    // 获取链接上的参数
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
  },
  mounted() {
    if (localStorage.isCookie != 1) {
      this.isCookie = true;
    }
    //获取地图
    request({ url: "getMap" })
      .then((e) => {
        this.mapInfo = e.data;
        this.getProgressBar(this.mapInfo.shareNum);
      })
      .catch((err) => console.log(err));

    //根据时间展示不同背景
    //     背景色直接按时间设定就行
    // 现在开始~10.14 11:00  黑色
    // 10.14 11:00~10.17 11:00  灰色
    // 10.17 11:00~     白色
    let now = new Date();
    let time1 = "2022-10-14 11:00";
    let time2 = "2022-10-17 11:00";
    console.log(new Date(time1).getTime());
    console.log(new Date(time1.replace(/-/g, "/")).getTime());
    if (now.getTime() >= new Date(time1).getTime()) {
      this.backColor = 2;
    }
    if (now.getTime() >= new Date(time2).getTime()) {
      this.backColor = 3;
    }
  },

  watch: {},
};
</script>
<style lang="less" scoped>
@import "../assets/css/public.less";

.Wrap {
  background-color: #181818;
  position: relative;
}

.pointBg {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0%;
  left: 0%;
  animation: opacity0to1 1000ms 200ms ease infinite alternate;
}
.mbg,
.pBg {
  width: 100%;
  height: 100vh;
}

.contentBox {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mapContent {
  background-color: rgba(15, 15, 15, 0.3);
}

.main {
  width: 100%;
  height: 100%;

  .bg {
    width: 100%;
    position: relative;
  }
  .contentBox {
    .logo {
      .attr_val(width, 127);
      .attr_val(margin-left, 31);
    }

    .mapContent {
      .attr_val(margin-top, 13);
      .attr_val(padding-bottom, 22);

      .mapTitle {
        .attr_val(width, 658);
        margin: 0 auto;
        // .attr_val(padding-bottom, 50);
        // .attr_val(padding-top, 20);
        .attr_val(padding-top, 70);
        position: relative;
        .titleIcon {
          position: absolute;
          .attr_val(width, 37);
          .attr_val(right, -37);
          .attr_val(top, 70);
          .titleLink {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .titleBottom {
          .attr_val(width, 600);
          margin: 0 auto;
          .attr_val(margin-top, -20);
        }
      }

      .RTBox {
        .attr_val(margin-top, 25);
        .attr_val(margin-left, 30);
        font-family: "zt";

        .rt {
          .attr_val(width, 244);
          position: relative;

          .rtNum {
            position: absolute;
            top: 0vw;
            .attr_val(left, 79);
            .attr_val(font-size, 25);
            .attr_val(line-height, 25);
            color: #3eafff;
          }
        }

        .likeBox {
          .attr_val(margin-top, 14);
          .attr_val(margin-left, 14);
          display: flex;
          align-items: center;
          color: @likeColor;
          .attr_val(font-size, 25);
          .attr_val(line-height, 25);

          .like {
          position: relative;

            .attr_val(width, 25);
            .attr_val(margin-right, 10);
            flex-shrink: 0;
            .likeBright {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              animation: likeClick 500ms ease both;
            }
          }
        }
      }

      .map {
        width: 100%;
        // .attr_val(height, 441);
        // .attr_val(height, 496);
        .attr_val(height, 520);
        margin-top: -0.5vw;
        box-sizing: border-box;
      }

      .btnBox {
        .attr_val(margin-top, 45);
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;

        .icon {
          .attr_val(width, 82);
          position: relative;

          .twLink {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            .attr_val(width, 82);
            .attr_val(height, 82);
          }

          .iconSon {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          }

          .bird {
            animation: bird 900ms ease infinite alternate;
          }

          .girl {
            animation: textBox1Rotate 900ms linear infinite;
          }

          .albumBox {
            position: absolute;
            bottom: 0;
            left: 0;
            overflow: hidden;
            // animation: albumBox 1500ms ease infinite;
            .attr_val(width, 82);
            .attr_val(height, 82);

            .album {
              .attr_val(width, 82);
              position: absolute;
              bottom: 0;
              left: 0;
              .attr_val(height, 82);
            }
          }

          .shareBox {
            position: absolute;
            bottom: 0;
            left: 0;
            animation: albumBox 1800ms ease infinite;
            .attr_val(width, 82);
            overflow: hidden;

            .shareIcon {
              .attr_val(width, 82);
              position: absolute;
              bottom: 0;
              left: 0;
              .attr_val(height, 82);
            }
          }

          .iconcBottom {
            position: absolute;
            bottom: -52%;
            right: 58%;
            .attr_val(width, 123);
            animation: textBox1 700ms ease both;

            // img {
            //   transform-origin: 60% 0%;
            //   animation: textBox1Rotate 1500ms 900ms linear infinite;
            // }
          }

          .icondBottom {
            position: absolute;
            bottom: -52%;
            right: -28%;
            .attr_val(width, 157);
            animation: textBox2 700ms ease both;

            // img {
            //   transform-origin: 60% 0%;
            //   animation: textBox2Rotate 1500ms 900ms linear infinite;
            // }
          }
        }

        .loginBtn {
          .attr_val(width, 304);
          position: relative;

          .loginBtnTriangle {
            width: 0;
            position: absolute;
            top: 0;
            left: 0;
            border-bottom: 1.733vw transparent solid;
            border-left: 1.733vw #fff solid;
          }

          .loginBtnText {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(../assets/images/loginBtnBgs.jpg) no-repeat;
            -webkit-mask: url(../assets/images/loginBtnText.png) no-repeat;
            -webkit-mask-position: 0% 0%;
            -webkit-mask-size: 100% 100%;
            // animation: loginMask 3100ms linear infinite alternate;
          }
        }
      }

      .award {
        .attr_val(margin-top, 77);

        width: 100%;

        .Rtbox {
          width: 100%;
          justify-content: space-around;
          display: flex;

          .itemRT {
            color: @progressRTColor;
            .attr_val(font-size, 14);
            font-family: "zt";
          }
          .itemRT1 {
            transform: translate(3.6vw, 0);
          }
          .itemRT3 {
            transform: translate(-3.6vw, 0);
          }
        }

        .progressBar {
          .attr_val(height, 14);
          .attr_val(margin-top, 14);
          .attr_val(margin-bottom, 14);
          width: 100%;
          position: relative;
          background-color: #000;

          .pillarBox {
            width: 100%;
            display: flex;
            justify-content: space-around;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);

            .pillar {
              .attr_val(width, 54);
            }
            .pillar1 {
              transform: translate(3.6vw, 0);
            }
            .pillar3 {
              transform: translate(-3.6vw, 0);
            }
          }

          .barBox {
            transition: all ease 0.5s;
            height: 100%;
            width: 0%;
            position: absolute;
            bottom: 0%;
            background-image: linear-gradient(
              to right,
              #152a37,
              #3babfb 96%,
              #fff 99%
            );
          }
        }

        .awardImgBox {
          display: flex;
          justify-content: space-around;

          .awardImg {
            .attr_val(width, 102);
          }
          .awardImg1 {
            transform: translate(3.6vw, 0);
          }
          .awardImg3 {
            transform: translate(-3.6vw, 0);
          }
        }
      }
    }

    .note {
      .attr_val(width, 545);
      margin: 0 auto;
      .attr_val(margin-top, 15);
    }
    .foot {
      text-align: center;
      .noteBottom {
        font-family: "zt";
        color: #fff;
        .attr_val(font-size, 20);
        text-decoration: underline;
        margin: auto;
      }
    }
  }
}
.cookieBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  .text {
    .attr_val(margin-top,25);
  }
  .cookie {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 100%;
    // font-family: "zt";
    background-color: #fff;
    .attr_val(font-size,25);
    .attr_val(padding,35);
    box-sizing: border-box;
    position: absolute;
    bottom: 0%;
    pointer-events: auto;
    .cookieBtn {
      box-sizing: border-box;
      text-align: center;
      .attr_val(margin-top, 30);
      .attr_val(padding, 20);
      background: #366cb4;
      color: #fff;
      border-radius: 3px;
      cursor: pointer;
    }
    .close {
      cursor: pointer;
      position: absolute;
      top: 1%;
      right: 1%;
      width: 40px;
      height: 40px;
      &::after,
      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 2px;
        background: #000;
        position: absolute;
        top: 50%;
        left: 50%;
      }
      &::after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}
@media (max-width: 700px) {
  .cookie {
    .cookieBtn {
      width: 100%;
    }
  }
}
@media (min-width: 700px) {
  .main .contentBox .mapContent .RTBox .rt .rtNum {
    .attr_val(font-size,25);
  }
  .cookieBox {
    .cookie {
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      .p_attr_val(font-size,25) !important;
      .p_attr_val(padding,35) !important;
      .text {
        width: 70%;
        margin-top: 0;
      }
      .cookieBtn {
        position: absolute;
        .p_attr_val(padding,20);
        background: #366cb4;
        color: #fff;
        bottom: 10%;
        right: 10%;
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }
}

.pcMain {
  width: 100%;
  height: 100%;

  .pcBg {
    width: 100%;
    position: relative;

    .bgText {
      .p_attr_val(width, 68);
      position: absolute;
      top: 45%;
      left: 0%;
      animation: bgText 7000ms linear infinite;
    }

    .bgTextRight {
      .p_attr_val(width, 150);
      position: absolute;
      top: 45%;
      right: 0%;
      animation: bgTextRight 7000ms linear infinite;
    }

    .pcBgJumpTop {
      position: absolute;
      top: 65%;
      left: 3.8%;
    }

    .pcBgJumpTop1 {
      position: absolute;
      top: 38%;
      left: 3.8%;
    }
  }

  .contentBox {
    .logo {
      .p_attr_val(width, 134);
      .p_attr_val(margin-left, 154);
    }

    .mapContent {
      position: relative;
      .p_attr_val(padding-top, 20);

      .mapTitlePc {
        .p_attr_val(width, 892);
        margin: 0 auto;
        position: relative;

        .titleIcon {
          position: absolute;
          .p_attr_val(width, 37);
          .p_attr_val(right, -17);
          .p_attr_val(top, 0);
          .titleLink {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .titleBottom {
          .p_attr_val(width, 700);
          margin: 0 auto;
          .p_attr_val(margin-top, -20);
        }
      }

      .map {
        .p_attr_val(width, 1415);
        .p_attr_val(height, 700);
        margin: 0 auto;
      }

      .pcBtnBox {
        display: flex;
        cursor: pointer;
        .p_attr_val(width, 139);
        .p_attr_val(margin-left, 271);
        margin-top: -3.5vw;
        flex-wrap: wrap;
        justify-content: space-around;

        .pcBtnIcon {
          .p_attr_val(width, 139);
          .p_attr_val(margin-bottom, 10);
          position: relative;

          .twLink {
            .p_attr_val(width, 139);
            .p_attr_val(height, 50);
            position: absolute;
            top: 0;
            left: 0;
          }

          .iconSon {
            position: absolute;
            left: 0;
            .p_attr_val(width, 55);
          }

          .bird {
            top: -5%;
            animation: bird 900ms ease infinite alternate;
          }

          .girl {
            top: -5%;
            animation: textBox1Rotate 900ms linear infinite;
          }

          .share {
            animation: pc_share 1500ms linear infinite;
            overflow: hidden;
            bottom: -7%;
            left: 0;

            img {
              position: absolute;
              bottom: 0;
              left: 0;
              .p_attr_val(width, 55);
              .p_attr_val(height, 55);
            }
          }
          .icondTextRt {
            position: absolute;
            .p_attr_val(top, 0);
            .p_attr_val(left, 2);

            .p_attr_val(width, 50);
          }
          .shareBox {
            .p_attr_val(width, 35);
            position: absolute;
            .p_attr_val(bottom, 0);
            .p_attr_val(left, -12);

            animation: pc_albumBox 1800ms ease infinite;
            overflow: hidden;
            .shareIcon {
              .p_attr_val(width, 62);
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }

      .RTBox {
        position: absolute;
        .p_attr_val(top, 192);
        .p_attr_val(left, 271);
        font-family: "zt";
        .rt {
          .p_attr_val(width, 244);
          position: relative;

          .rtNum {
            text-align: center;
            position: absolute;
            top: 0;
            .p_attr_val(top, -10);

            .p_attr_val(left, 73);
            // .p_attr_val(width, 130);
            color: #3eafff;
            .p_attr_val(font-size, 25);
            font-family: "zt";
          }
        }

        .likeBox {
          .p_attr_val(margin-top, 14);
          .p_attr_val(margin-left, 15);
          display: flex;
          align-items: center;
          color: @likeColor;
          .p_attr_val(font-size, 20);

          .like {
            cursor: pointer;
            position: relative;

            .p_attr_val(width, 25);
            .p_attr_val(margin-right, 10);
            .likeBright {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              animation: likeClick 500ms ease both;
            }
          }
        }

        .pcBtnIcon {
          .p_attr_val(width, 139);
          .p_attr_val(margin-top, 20);
          cursor: pointer;
          position: relative;
          .loginBtnTriangle {
            width: 0;
            position: absolute;
            top: 0;
            left: 0;
            border-bottom: 0.2vw transparent solid;
            border-left: 0.2vw #fff solid;
          }
          .albumBox {
            position: absolute;
            .p_attr_val(left, 8);
            .p_attr_val(bottom, 9);
            overflow: hidden;
            // animation: pc_albumBox 1500ms ease infinite;
            .p_attr_val(width, 32);
            .p_attr_val(height, 32);

            .album {
              .p_attr_val(width, 28);
              position: absolute;
              bottom: 0;
              left: 0;
              .p_attr_val(height, 24);
            }
          }

          .pcIconcBottom {
            position: absolute;
            bottom: -52%;
            right: 10%;
            .p_attr_val(width, 113);
            animation: pc_textBox 700ms ease both;

            // img {
            //   transform-origin: 60% 0%;
            //   animation: textBox1Rotate 1500ms 900ms linear infinite;
            // }
          }
        }
      }

      .awardPc {
        color: #fff;
        position: absolute;
        top: 8%;
        right: 10%;
        display: flex;
        flex-direction: column;
        height: 85%;
        justify-content: space-around;

        .item {
          .Rt {
            .p_attr_val(width, 115);
            .p_attr_val(font-size, 20);
            white-space: nowrap;
            font-family: "zt";
          }

          .awardImgBox {
            display: flex;
            align-items: center;
            .pillar {
              .p_attr_val(width, 55);
              transform: translate(0, -29%);
              z-index: 2;
            }
            .awardImg {
              .p_attr_val(width, 112);
              .p_attr_val(margin-left, 0);
            }
          }
        }

        .preBar {
          z-index: 1;
          display: block;
          .p_attr_val(width, 9);
          height: 200%;
          position: absolute;
          top: 17%;
          right: 14.5%;
          background-color: #000;
          .barBox {
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            display: flex;
            flex-direction: column-reverse;
            .pre {
              transition: all ease 0.5s;
              order: 2;
              width: 100%;
              height: 0%;
              background-image: linear-gradient(
                to top,
                #152a37,
                #2484c7 96%,
                #dcf2ff 99%
              );
            }
            .bottomHeight {
              transition: all ease 0.5s;
              height: 0%;
              background-color: #152a37;
            }
          }
        }
      }
    }

    .loginBtn {
      cursor: pointer;
      .p_attr_val(width, 300);
      margin: 0 auto;
      .p_attr_val(margin-top, 25);
      position: relative;

      .loginBtnTriangle {
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: 0.4vw transparent solid;
        border-left: 0.4vw #fff solid;
      }

      .loginBtnText {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(../assets/images/loginBtnBg.jpg) no-repeat;
        -webkit-mask: url(../assets/images/loginBtnText.png) no-repeat;
        -webkit-mask-position: 0% 0%;
        -webkit-mask-size: 100% 100%;
        // animation: loginMask 3100ms linear infinite alternate;
      }
    }

    .note {
      .p_attr_val(width, 549);
      margin: 0 auto;
      .p_attr_val(margin-top, 17);
    }
  }
  .foot {
    text-align: center;
    .noteBottom {
      font-family: "zt";
      color: #fff;
      .p_attr_val(font-size, 15);
      text-decoration: underline;
      margin: auto;
    }
  }
}
</style>