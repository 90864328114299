import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false

Vue.prototype.$broadcast = function (eventName, vlaue) {
  let broadcast = (target) => {
    target.$children.forEach(child => {
      child.$emit(eventName, vlaue)
      if (child.$children && child.$children.length > 0) {
        broadcast(child)
      }
    });
  }
  broadcast(this);
}


var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?7ce092cb9805f83290ce867fa1d9f7e5";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();


window.onresize = e => {
  console.log(9988)
  temp.$broadcast("onresiz", e);
}
let temp = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
