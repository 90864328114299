let cjs = {

    loads: {
        loadPath: "./fla/",

        loadEndObj: {},

        /**
         * 
         * @param {String} fileName 要加载的文件名称
         * @param {String} key 要加载的文件唯一key
         * @param {function} success 成功的回调
         * @param {function} error 失败的回调
         * @returns 
         */
        load(fileName, path, key) {

            return new Promise((resolve, reject) => {

                if (!fileName) { reject(); return ''; }

                if (!!this.loadEndObj[fileName]) {
                    resolve(this.loadEndObj[fileName])
                    return this.loadEndObj[fileName];
                }

                this.loadJs(path + fileName).then(e => {

                    var loader = new createjs.LoadQueue(true);

                    var comp = AdobeAn.getComposition(key);
                    loader.addEventListener("fileload", (evt) => { this.handleFileLoad(evt, comp) });
                    loader.addEventListener("complete", (evt) => {
                        this.handleComplete(evt, comp, resolve)
                    });
                    var lib = comp.getLibrary();

                    lib.properties.manifest.forEach(e => {
                        // e.src = path+ e.src
                        e.src = "https://nikkecdn.fftu.cn/" + e.src
                    })

                    loader.loadManifest(lib.properties.manifest);
                }).catch(e => {
                    error && error('加载错误');
                });
            })

        },
        handleFileLoad(evt, comp) {
            var images = comp.getImages();
            if (evt && (evt.item.type == "image")) { images[evt.item.id] = evt.result; }
        },
        handleComplete(evt, comp, resolve) {

            //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
            var lib = comp.getLibrary();
            var ss = comp.getSpriteSheet();
            var queue = evt.target;
            var ssMetadata = lib.ssMetadata;
            for (let i = 0; i < ssMetadata.length; i++) {
                ss[ssMetadata[i].name] = new createjs.SpriteSheet({ "images": [queue.getResult(ssMetadata[i].name)], "frames": ssMetadata[i].frames })
            }

            createjs.Ticker.framerate = lib.properties.fps;

            resolve(lib);
        },
        /**
         * 
         * @param {string} name 获取要使用的原件对象名称
         */
        getLibrary(name) {

        },

        /**
         * 
         * @param {string} path 加载js文件
         */
        loadJs(path) {

            return new Promise(function (resolve, reject) {
                var head = document.getElementsByTagName("HEAD").item(0)
                var script = document.createElement("script")
                script.type = "text/javascript"
                script.onload = resolve
                script.onerror = reject
                script.src = path
                head.appendChild(script)
            })
        }
    }
}

export default cjs