<template>
  <div class="jumpWrap" :class="{ jumpWrapRight: isRight }">
    <div
      class="jumpItem"
      :style="{ 'animation-delay': `${i * 100}ms` }"
      v-for="i in 16"
      :key="i"
    ></div>
  </div>
</template>
<script>
export default {
  name: "jump",
  props: {
    isRight: {
      type: Boolean,
    },
  },
};
</script>
<style lang="less" scoped>
@import "../assets/css/public.less";
.jumpWrap {
  transform: rotate(-90deg);
  display: flex;
  justify-content: space-between;
  .attr_val(width,91);
  .attr_val(height,12);
  .jumpItem {
    .attr_val(width,1);
    background-color: #c7c8cf;
    animation: jump 800ms 100ms linear alternate infinite both;
    height: 1.6vw;
  }
}
.jumpWrapRight{
    transform:  rotate(-90deg) rotateX(180deg);
}

@media (min-width:1000px) {
  .jumpWrap {
  .p_attr_val(width,91);
  .p_attr_val(height,12);
  .jumpItem {
    .p_attr_val(width,1);
    animation: pc_jump 800ms 100ms linear alternate infinite both;
  }
}
}
</style>