<template>
  <div
    class="paintingWrap"
    @click="closePaint"
    :style="{ 'background-color': isAllScreen ? '#000' : '' }"
  >
    <div class="paintBox" :class="{ isAllScreenBox: isAllScreen }">
      <div class="roleImg" @click.stop="isAllScreen = false">
        <img
          class="roleImages"
          :src="
            !isAllScreen ? roleInfo.picture + '!ys901080' : roleInfo.picture
          "
          alt=""
        />
        <div class="allScreenClose" v-show="isAllScreen">
          <img
            @click.stop="closePaint"
            src="../assets/images/viewPaint/closeBtn.png"
            alt=""
          />
        </div>
      </div>
      <div
        class="paintContent"
        @click.stop=""
        :class="{ ispaintShow: isAllScreen }"
      >
        <div class="btnBox" :class="{ nooffbtnBox: !isoffShow }">
          <!-- 加上音量后删除style -->
          <!-- <div style="visibility: hidden; order: 1;" class="sondBtn" @click.stop="sondPlay">
            <img src="../assets/images/viewPaint/sondBtn.png" alt="" />
            <img class="sondBtn1" :class="{ sondBtn1Show: isSondPlay }" src="../assets/images/viewPaint/sondBtn1.png" alt="" />
            <img class="sondBtn2" :class="{ sondBtn2Show: isSondPlay }" src="../assets/images/viewPaint/sondBtn2.png" alt="" />
          </div> -->

          <div class="downLoad" @click.stop="downLoad">
            <img src="../assets/images/viewPaint/downBtn.png" alt="" />

            <img
              class="ismb"
              style="
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
              "
              :src="roleInfo.picture"
              alt=""
            />
          </div>
          <div class="allScreen" @click.stop="allScreen">
            <img src="../assets/images/viewPaint/allScreenBtn.png" alt="" />
          </div>
          <div
            class="officialLinkBtn"
            v-if="isoffShow"
            @click.stop="officialLinkClikc"
          >
            <img src="@/assets/images/viewPaint/officialLinkBtn.png" alt="" />
          </div>
        </div>
        <div class="centerBox">
          <div class="shareBorder">
            <img src="../assets/images/viewPaint/shareBorder.png" a lt="" />
          </div>
          <div class="shareContent">
            <div class="shareBtn" @click.stop="shareClick">
              <img src="../assets/images/viewPaint/shareBtn.png" alt="" />
            </div>
            <div class="shareNum" v-if="shareNum">
              {{ shareNum }}
            </div>
            <div class="shareNum" v-else>
              {{ roleInfo.shareNum ? roleInfo.shareNum : 0 }}
            </div>
            <div class="like" @click.stop="likeClick">
              <img
                :style="{ visibility: isLike ? 'hidden' : 'visible' }"
                src="../assets/images/viewPaint/likeBtn.png"
                alt=""
              />
              <img
                v-if="isLike"
                class="likeBright"
                src="../assets/images/viewPaint/likeBright.png"
                alt=""
              />
            </div>
            <div class="likeNum" v-if="likeNum">
              {{ likeNum }}
            </div>
            <div class="likeNum" v-else>
              {{ roleInfo.likeNum ? roleInfo.likeNum : 0 }}
            </div>
          </div>
        </div>
        <div class="bottomBox">
          <div class="roleName">{{ roleInfo.roleNmae }}</div>
          <div class="addressName">
            <span> {{ roleInfo.intro }}</span>
            <div class="blankBox1 blankBox"></div>
            <div class="blankBox2 blankBox"></div>
          </div>
        </div>
        <div class="closePaint">
          <img
            class="closeBorder"
            src="../assets/images/viewPaint/closeBtnBg.png"
            alt=""
          />
          <img
            @click.stop="closePaint"
            class="closeBtn"
            src="../assets/images/viewPaint/closeBtn.png"
            alt=""
          />
        </div>
        <a
          v-show="roleInfo.hyperLink"
          class="painterLink"
          target="_blank"
          :href="roleInfo.hyperLink"
          >［調査報告の原本はこちら］</a
        >
      </div>
    </div>

    <div v-if="pcDownLoad" class="downLoadWrap ispc">
      <div class="confimBox" @click.stop="">
        <div>
          <div class="confimTitle">このイラストを保存しますか？</div>
          <div class="btnBox">
            <div class="btn btn1" @click.stop="pcDownLoadClick">
              保存
              <a
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                "
                :href="
                  roleInfo.picture +
                  '?response-content-type=application/octet-stream'
                "
                :download="roleInfo.roleNmae + '.jpg'"
              ></a>
            </div>
            <div class="btn btn2" @click.stop="pcDownLoad = false">
              キャンセル
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/util/request";
import { Toast } from "vant";
import { Howl } from "howler";
import { IsMobile } from "@/util/util";
import { eventClick } from "@/util/util";

export default {
  name: "ViewPainting",
  props: {
    roleInfo: {},
  },
  data() {
    return {
      isAllScreen: false, // 立绘全屏
      isSondPlay: false,
      likeInfo: [], //记录谁被点赞了
      shareInfo: [], //记录谁被分享了
      isLike: false,
      likeNum: "",
      shareNum: "",
      roleSound: "",
      isMobile: IsMobile(),
      pcDownLoad: false,
      offShowArr: [1, 19, 21, 26, 31, 34, 38, 39, 41, 42, 43, 44],
      isoffShow: false,
    };
  },
  methods: {
    closePaint() {
      this.isSondPlay = false;
      // 有声音后加上
      // this.roleSound.stop();
      this.isAllScreen = false;
      this.$emit("closePaint");
    },
    allScreen() {
      this.isAllScreen = true;
    },
    // 点赞
    likeClick() {
      if (this.isLike || this.likeInfo.some((n) => n.id == this.roleInfo.id)) {
        return;
      }
      request({
        url: "submitRecord",
        params: {
          type: "0",
          id: this.roleInfo.id,
        },
      }).then((res) => {
        if (res.state == 0) {
          this.likeInfo.push({ id: this.roleInfo.id, like: true });
          this.isLike = true;
          this.likeNum = res.data.likeNum;
          eventClick(`点赞角色${this.roleInfo.id}`);
        }
      });
    },
    // 角色官推
    officialLinkClikc() {
      if (!this.roleInfo.officialLink) {
        return;
      }
      eventClick(`角色官推${this.roleInfo.id}`);
      setTimeout(() => {
        window.open(this.roleInfo.officialLink);
      }, 50);
    },
    shareClick() {
      if (this.shareInfo.some((n) => n.id == this.roleInfo.id)) {
        return;
      }
      eventClick(`分享角色${this.roleInfo.id}`);
      request({
        url: "submitRecord",
        params: {
          type: "1",
          id: this.roleInfo.id,
        },
      }).then((res) => {
        if (res.state == 0) {
          this.shareInfo.push({ id: this.roleInfo.id, share: true });
          this.shareNum = res.data.shareNum;
        }
      });
      let text = `【＃NIKKE事前登録受付中】47都道府県に ＃NIKKE たちが上陸中！？どのNIKKEがあなたの住んでいるエリアへやってくるか、ぜひCHECKしよう！
＃ニケ日本上陸プロジェクト`;
      // let link = location.href; // 分享的页面
      let link = `https://www.nikke-47campaign.com/index${this.roleInfo.id}.html?v4`; // 分享的页面
      setTimeout(() => {
        window.open(
          `https://twitter.com/intent/tweet?url=` +
            encodeURIComponent(link) +
            "&text=" +
            encodeURIComponent(text)
        );
      }, 50);
    },
    pcDownLoadClick() {
      this.pcDownLoad = false;
      request({
        url: "submitRecord",
        params: {
          type: "3",
          id: this.roleInfo.id,
        },
      })
        .then()
        .catch();
    },
    audioPlayer() {
      // let that = this;
      this.roleSound = new Howl({
        src: this.roleInfo.audioLink,
        autoplay: false,
        loop: false,
        volume: 1,
        html5: true,
        preload: true,
        mute: false,
        rate: 1,
      });
    },
    sondPlay() {
      this.isSondPlay = !this.isSondPlay;
      if (this.isSondPlay) {
        this.roleSound.play();
      } else {
        this.roleSound.stop();
      }
    },
    downLoad() {
      if (this.isMobile) {
        Toast("長押しして画像を保存");
        request({
          url: "submitRecord",
          params: {
            type: "3",
            id: this.roleInfo.id,
          },
        })
          .then()
          .catch();
      } else {
        this.pcDownLoad = true;
      }
    },
  },
  updated() {
    console.log(this.roleInfo);
  },
  watch: {
    roleInfo: {
      handler(newVal, oldVal) {
        if (newVal.id) {
          this.likeNum = "";
          this.shareNum = "";
          this.isoffShow = false;
          // 控制是否显示offbtn
          if (this.offShowArr.some((n) => n == newVal.id)) {
            console.log(this.isoffShow,'this.isoffShow')
            this.isoffShow = true;
          }
          // 有声音后加上
          // this.audioPlayer();
          if (this.likeInfo.some((n) => n.id == newVal.id)) {
            this.isLike = true;
          } else {
            this.isLike = false;
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@import "../assets/css/public.less";

.paintingWrap {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;

  .paintBox {
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: center0to1 800ms ease both;
    .roleImg {
      max-height: 70vh;
      overflow: hidden;
      position: relative;
      .roleImages {
        height: 100%;
        object-fit: cover;
      }
      .allScreenClose {
        position: absolute;
        right: 0;
        transform: translate(100%, 0);
        .attr_val(top, 0);
        .attr_val(width,33);
      }
    }
    .paintContent {
      // background-image: linear-gradient(
      //   to right,
      //   rgba(255, 255, 255, 0.2) 10%,
      //   transparent
      // );
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      .attr_val(padding-left,31);
      position: absolute;
      top: 0%;
      left: 0%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      transition: all 300ms;

      .btnBox {
        // .attr_val(width,221);
        .attr_val(width,350);

        display: flex;
        align-items: center;
        justify-content: space-between;
        .sondBtn,
        .downLoad,
        .allScreen {
          position: relative;
          .attr_val(width,64);
        }
        .sondBtn {
          position: relative;
          .sondBtn1,
          .sondBtn2 {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
          .sondBtn1Show {
            animation: opacity1to0 1000ms 200ms linear infinite;
          }
          .sondBtn2Show {
            animation: opacity1to0 1000ms 400ms linear infinite;
          }
        }
        .officialLinkBtn {
          .attr_val(width,193);
        }
      }
      .nooffbtnBox {
        .attr_val(width,150);
      }
      .centerBox {
        margin-left: -2%;
        display: flex;
        align-items: center;
        .shareBorder {
          .attr_val(width,1);
        }
        .shareContent {
          .attr_val(margin-left,11);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .shareBtn {
            .attr_val(width,114);
          }
          .shareNum,
          .likeNum {
            .attr_val(font-size,15);
            font-family: "zt";
          }
          .like {
            position: relative;
            .attr_val(width,39);
            .likeBright {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              animation: likeClick 500ms ease both;
            }
          }
        }
      }
      .bottomBox {
        font-family: "zt";

        .roleName {
          .attr_val(font-size,32);
          font-weight: 600;
        }
        .addressName {
          .attr_val(font-size,20);
          .attr_val(margin-top,20);
          .attr_val(padding-left,36);
          .attr_val(padding-right,20);
          .attr_val(height,63);
          .attr_val(line-height,63);
          font-weight: 500;

          display: inline-block;
          position: relative;
          background-color: #fff;
          margin-left: -5.5vw;
          .blankBox {
            .attr_val(width,2);
            .attr_val(height,12);
            .attr_val(left,9);
            position: absolute;
            background-color: #000;
          }
          .blankBox1 {
            .attr_val(top,39);
          }
          .blankBox2 {
            .attr_val(top,10);
          }
        }
        .addressName::after {
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          position: absolute;
          top: 0;
          right: -3.6vw;
          border-left: 3.6vw solid #fff;
          border-top: 8.4vw solid transparent;
        }
      }
      .closePaint {
        position: absolute;
        .attr_val(width,399);
        .attr_val(top,8);
        right: 0;
        height: 100%;
        .closeBorder {
          pointer-events: none;
        }
        .closeBtn {
          .attr_val(width,33);
          position: absolute;
          .attr_val(top,7);
          .attr_val(right,11);
        }
      }
    }
    .ispaintShow {
      // opacity: 0;
      z-index: -1;
      display: none;
    }
  }
}
.painterLink {
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 100%);
  font-family: "zt";
  bottom: 0;
  color: #fff;
  width: 100%;
  text-align: center;
  text-decoration: underline;
}
@media (max-width: 1000px) {
  .paintingWrap .paintBox .paintContent .closePaint {
    height: 85%;
    overflow: hidden;
  }
  .painterLink {
    .attr_val(font-size,20);
    .attr_val(height,50);
    .attr_val(line-height,50);
  }
}
@media (min-width: 1000px) {
  .painterLink {
    .p_attr_val(font-size,20);
    .p_attr_val(height,50);
    .p_attr_val(line-height,50);
  }
  .paintingWrap {
    .paintBox {
      // transition: all ease 0.3s;
      .p_attr_val(width,890);

      .paintContent {
        .p_attr_val(padding-left,31);

        .btnBox {
          // .p_attr_val(width,223);
          .p_attr_val(width,350);
          .sondBtn,
          .downLoad,
          .allScreen {
            cursor: pointer;
            .p_attr_val(width,64);
          }
          .officialLinkBtn {
            .p_attr_val(width,193);
            cursor: pointer;
          }
        }
        .nooffbtnBox{
          .p_attr_val(width,150);

        }
        .centerBox {
          margin-left: -1.43%;
          .shareBorder {
            .p_attr_val(width,1);
          }
          .shareContent {
            .p_attr_val(margin-left,11);
            .shareBtn {
              .p_attr_val(width,114);
              cursor: pointer;
            }
            .shareNum,
            .likeNum {
              .p_attr_val(font-size,25);
            }
            .like {
              .p_attr_val(width,39);
              .p_attr_val(margin-top,25);
              cursor: pointer;
            }
          }
        }
        .bottomBox {
          .roleName {
            .p_attr_val(font-size,35);
          }
          .addressName {
            .p_attr_val(font-size,20);
            .p_attr_val(margin-top,20);
            .p_attr_val(padding-left,36);
            .p_attr_val(padding-right,20);
            .p_attr_val(height,63);
            .p_attr_val(line-height,63);
            margin-left: -1.5vw;
            .blankBox {
              .p_attr_val(width,2);
              .p_attr_val(height,12);
              .p_attr_val(left,9);
            }
            .blankBox1 {
              .p_attr_val(top,39);
            }
            .blankBox2 {
              .p_attr_val(top,10);
            }
          }
          .addressName::after {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
            right: -2vw;
            border-left: 2vw solid #fff;
            border-top: 2.6vw solid transparent;
          }
        }
        .closePaint {
          position: absolute;
          .p_attr_val(width,399);
          .p_attr_val(top,-37.5);
          .p_attr_val(right,-52.5);
          .closeBtn {
            cursor: pointer;
            .p_attr_val(width,33);
            position: absolute;
            .p_attr_val(top,7);
            .p_attr_val(right,11);
          }
        }
      }
    }
  }
  .allScreenClose {
    .p_attr_val(width,33) !important;
    cursor: pointer;
  }

  .downLoadWrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    pointer-events: none;
    .confimBox {
      overflow: hidden;
      font-family: "zt";
      pointer-events: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .p_attr_val(width, 550);
      color: #686464;
      background: #fff;
      border-radius: 10px;
      .p_attr_val(padding-top,40);

      .confimTitle {
        width: 100%;
        text-align: center;
        .p_attr_val(margin-bottom,20);
      }
      .viewImg {
        width: 70%;
        max-height: 70%;
        object-fit: contain;
        margin: 0 auto;
      }
      .btnBox {
        .p_attr_val(margin-top,40);
        width: 100%;
        display: flex;
        align-items: center;
        .btn {
          width: 50%;
          text-align: center;
          .p_attr_val(line-height,60);
          .p_attr_val(height,60);
          .p_attr_val(font-size,30);
          .p_attr_val(padding,10);
          border-top: 1px solid #f1f1f1;
        }
        .btn1 {
          position: relative;
          order: 2;
          color: #558ad3;
        }
        .btn2 {
          cursor: pointer;
          color: #686464;
          border-right: 1px solid #f1f1f1;
        }
      }
    }
  }
}

.isAllScreenBox {
  width: auto !important;
  .roleImg {
    max-height: 100vh !important;
    overflow: visible !important;
    .roleImages {
      width: auto;
      height: auto;
      max-width: 100vw !important;
      max-height: 100vh !important;
      object-fit: contain !important;
      // margin: 0 auto;
    }
  }
}
</style>