import axios from "axios";
// const request = options => {
//     return new Promise((resolve, reject) => {
//         let { url, method, data } = options;
//         axios.defaults.timeout = 30000;//设置请求超时时间
//         axios.defaults.baseURL = 'http://192.168.3.9:8093/api/nikke/'
//         //   //设置请求头
//         //   axios.defaults.headers.post['Content-Type'] = headerType || 'application/x-www-form-urlencoded';
//         //请求拦截
//         axios.interceptors.request.use(config => {
//             console.log('aaa')
//             return config;
//         }, error => {
//             console.log(error)
//         })

//         //响应拦截
//         axios.interceptors.response.use(response => {
//             let { data, headers } = response;//取出服务端返回的数据和请求头
//             //判断自定义错误并将错误包装后抛出，这里抛出错误后axios会执行.catch分支捕获异常，向方法调用者返回错误
//             //500为识别到其他错误
//             if (data.status === 500) {
//                 //对于其他的500错误，均包装为网络请求错误，并附带上相应的错误信息进行抛出
//                 throw new Error(`网络请求错误:${data.msg}`);
//             }
//             return response;//结束拦截，继续执行请求
//         }, error => {
//             //如果出现了真正的请求错误，则依然需要在方法调用者启用了全局loading效果时关闭elementUI的Loading效果，并返回错误
//             return Promise.reject(error);
//         })


//         if (method == 'get') {
//             axios.get(url, {
//                 params: data
//             }).then(res => {
//                 resolve(res);
//             }).catch(err => {
//                 reject(err);
//             })
//         } else if (method == 'post') {
//             axios.post(url, { data: data }).then(res => {
//                 resolve(res);
//             }).catch(err => {
//                 reject(err);
//             })
//         }
//     })

// }

// export default request;

export default function request(config) {
    const instance = axios.create({
        baseURL: '/api/nikke/',
        timeout: 5000,
        method: config.method || 'get'
    })
    //请求拦截
    instance.interceptors.request.use(config => {
        return config;
    }, error => {
        console.log(error)
    })
    //响应拦截
    instance.interceptors.response.use(res => {
        if (res.status === 500) {
            //对于其他的500错误，均包装为网络请求错误，并附带上相应的错误信息进行抛出
            throw new Error(`网络请求错误:${res.msg}`);
        }
        return res.data
    }), err => {
        console.log(err)
    }
    //发送真正的网络请求
    return instance(config)
}