<template>
  <div class="box" ref="mapBox">
    <canvas class="mapCanvas" :width="getStageSize.width" :height="getStageSize.height" ref="mapCanvas"
      :style="getStageSize"></canvas>

  </div>
</template>
<script>
import createEase from '@/util/createEase'
import touch from 'touchjs'
import { IsMobile, prefix, isCollision, bindEvent } from '@/util/util'
import request from "@/util/request";
export default {
  props: ["mapInfo"],
  name: "Score",
  data() {
    return {

      isMobile: IsMobile(),
      stageSize: {
        scale: 1,
        mobile: {
          w: 750,
          h: 494,
          centerX: 750 / 2,
          centerY: 494 / 2,
          width: "750px",
          height: "494px",
          minScale: 1,
          maxScale: 2
        }, pc: {
          w: 1150,
          h: 600,
          centerX: 1150 / 2,
          centerY: 600 / 2,
          width: "1150px",
          height: "600px",
          minScale: 1,
          maxScale: 1.3
        }
      },

      //区块显示映射关系
      blockLight: [
        // {
        //   "blockNum": "47",                 //区块序号
        //   "isMobile": 1,                	//0:否，1:是
        //   "lightBlockId": "12,11"							//1,2,3,4
        // }
      ],

      mapCount: 47,//47个地图小块
      isRunEvent: true,

      stage: undefined,
      exportRoot: undefined,
      startX: undefined,
      startY: undefined,
      startScale: 1,

      cueSelectIdx: null,

      isPcMove: false//兼容pc的事件使用
    }
  },
  computed: {
    getMapObj() {
      return this.isMobile ? this.exportRoot.mobileMap : this.exportRoot.pcMap
    },
    getStageSize() {
      let size = this.isMobile ? this.stageSize.mobile : this.stageSize.pc;
      size[prefix() + "transform"] = "scale(" + this.stageSize.scale + ")"

      size[prefix() + "transform-origin"] = "0 0"
      return size
    },
    // getDefMapInfo() {
    //   console.log(this.mapInfo, 666)
    //   return this.mapInfo
    // }
  },
  watch: {
    mapInfo: {
      handler(newData, oldData) {
        if (!!newData.MapInfoList && newData.MapInfoList.length > 0) {
          //展示默认数据
          this.showDefRegion()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    //显示默认需要显示的数据
    showDefRegion() {
      //防止两个异步导致画布不存在
      if (this.stage === undefined) {
        setTimeout(() => {
          this.showDefRegion()
        }, 100);
        return
      }

      if (!this.isRunEvent) return
      this.isRunEvent = false

      //隐藏其他的动画 这里动画要执行300ms
      this.stopAnim([], 0)
      //隐藏所有选中区域
      // this.setBrAlpha()

      let showRegion = []
      let srartAnim = []

      this.mapInfo.MapInfoList.forEach(e => {
        //显示缩略图
        if (e.isShowPc == 1 && !this.isMobile) srartAnim.push(e.No)
        if (e.isShow == 1 && this.isMobile) srartAnim.push(e.No)

        //显示背景
        if (e.isLight == 1) showRegion.push(e.No)
      })

      //然后执行动画
      setTimeout(() => {
        //启动图钉动画
        this.startAnim(srartAnim)
        //批量设置背景色
        this.setBrAlpha(showRegion, 1)
        this.isRunEvent = true
      }, 50);
    },

    resize() {

      if (this.stage === undefined) return ''

      //mobile flash画布宽高 750 500

      let width = this.$refs.mapBox.clientWidth
      let height = this.$refs.mapBox.clientHeight

      this.stageSize.scale = width / this.getStageSize.w;
      this.$emit('complateScale')
      // console.log(this.exportRoot)

    },

    fnStartAnimation() {
      createjs.Ticker.framerate = 24
      createjs.Ticker.addEventListener("tick", e => {
        // console.log(111)
        if (this.stage) {
          this.stage.update()
        }
      });
    },
    bindTouchEvent() {
      //防止拖拽走
      touch.on(this.$refs.mapBox, "touchmove", e => {
        e.preventDefault()
        // e.stopPropagation()
      })

      touch.on(this.$refs.mapBox, "pinch", e => {
        if (!this.isRunEvent) return

        let temp = this.startScale * e.scale
        // let temp = e.scale > 1 ? this.stageSize.mobile.maxScale : this.stageSize.mobile.minScale

        if (temp > this.stageSize.mobile.maxScale) temp = this.stageSize.mobile.maxScale
        if (temp < this.stageSize.mobile.minScale) temp = this.stageSize.mobile.minScale

        //如果缩放值改变的话就需要处理偏移量 让缩放以屏幕中心来缩放
        if (this.exportRoot.scale != temp) this.scaleDeviation(this.exportRoot.scale, temp)
        this.exportRoot.scale = temp
      })

      touch.on(this.$refs.mapBox, "pinchend", e => {
        if (!this.isRunEvent) return
        this.isRunEvent = false
        //处理放大缩小后移动到地图中间
        let scale = this.exportRoot.scale > this.startScale ? this.stageSize.mobile.maxScale : this.stageSize.mobile.minScale
        //默认缩小
        let pt = { x: 0, y: 0 }
        //如果是放大 剧中的位置就不一样
        if (scale == this.stageSize.mobile.maxScale) {
          let bounds = this.exportRoot.getBounds()
          pt = { x: bounds.width / 2 * -1, y: bounds.height / 2 * -1 }
        }

        let json = Object.assign({ scale: scale }, pt)
        createjs.Tween.get(this.exportRoot, { override: true }).to(json, 300).call(e => {
          this.isRunEvent = true
        })

        this.startScale = this.exportRoot.scale
      })
      touch.on(this.$refs.mapBox, "dragstart", e => {
        // e.preventDefault()
        if (!this.isRunEvent) return
        // console.log(111)
        this.startX = this.exportRoot.x
        this.startY = this.exportRoot.y

        //隐藏其他的动画 这里动画要执行300ms 应为拖拽开始需要停止动画和选中的区域
        this.stopAnim([], 0)
        //隐藏所有选中区域
        // this.setBrAlpha()
        this.cueSelectIdx = null
      })
      touch.on(this.$refs.mapBox, "drag", e => {
        if (!this.isRunEvent || this.startX === undefined) return
        // console.log(222);
        [this.exportRoot.x, this.exportRoot.y] = [this.startX + e.distanceX, this.startY + e.distanceY]
      })
      touch.on(this.$refs.mapBox, "dragend", e => {
        if (!this.isRunEvent || this.startX === undefined) return
        this.startX = undefined
        // console.log(333)
        //计算在中心的直接移动
        this.getCenterRegion()
      })

      this.bindCjsClick()

    },
    bingPcEvent() {

      //可能是要兼容火狐的代码
      // bindEvent(this.$refs.mapBox, "DOMMouseScroll", e => {
      //   e = e || window.event;
      //   console.log(e.wheelDelta, 22)
      // })
      // this.stage.on("rollout", e => {
      //   console.log(e)
      // })
      //绑定滚轴事件
      bindEvent(this.$refs.mapBox, "mousewheel", e => {
        e = e || window.event;
        if (!this.isRunEvent) return

        //动态缩放
        let scale = 1;
        if (e.wheelDelta > 0) {
          scale = this.stageSize.pc.maxScale
        }
        else {
          scale = this.stageSize.pc.minScale
        }
        // createjs.Tween.removeTweens(this.exportRoot)
        let pt = this.scaleDeviation(this.exportRoot.scale, scale, false)
        let json = Object.assign({ scale: scale }, pt)
        createjs.Tween.get(this.exportRoot, { override: true }).to(json, 300)

        //以前的即时缩放
        // if (e.wheelDelta > 0) e.scale = 1.05
        // else e.scale = 0.95

        // let temp = this.exportRoot.scale * e.scale
        // if (temp > this.stageSize.pc.maxScale) temp = this.stageSize.pc.maxScale
        // if (temp < this.stageSize.pc.minScale) temp = this.stageSize.pc.minScale

        // // //如果缩放值改变的话就需要处理偏移量 让缩放以屏幕中心来缩放
        // if (this.exportRoot.scale != temp) this.scaleDeviation(this.exportRoot.scale, temp)

        // this.exportRoot.scale = temp
      })

      this.exportRoot.on("mousedown", e => {

        if (!this.isRunEvent) return
        // this.exportRoot.cursor = "pointer"
        this.startX = e.stageX
        this.startY = e.stageY

        //隐藏其他的动画 这里动画要执行300ms 应为拖拽开始需要停止动画和选中的区域
        this.stopAnim([], 0)
        //隐藏所有选中区域
        // this.setBrAlpha()
        this.cueSelectIdx = null


        // this.$refs.mapBox.style.cursor = "all-scroll"

      })
      this.exportRoot.on("pressmove", e => {
        if (!this.isRunEvent || this.startX === undefined) return;
        this.exportRoot.x = this.exportRoot.x + (e.stageX - this.startX)
        this.exportRoot.y = this.exportRoot.y + (e.stageY - this.startY)
        this.startX = e.stageX
        this.startY = e.stageY
        this.isPcMove = true
      })
      this.exportRoot.on("pressup", e => {

        if (!this.isRunEvent || this.startX === undefined) return;
        //计算在中心的直接移动
        this.getCenterRegion()
        //控制初始值
        this.startX = undefined
        // this.exportRoot.cursor = null
        this.isPcMove = false
      })


      this.bindCjsClick()
    },

    bindCjsClick() {
      //绑定47个地图板块点击事件
      let temp = this.getMapObj.brightRegion

      for (let i = 1; i <= this.mapCount; i++) {
        temp["br" + i].on("click", e => {

          //如果是pc 并且移动过 那就不触发点击
          if (!this.isMobile && this.isPcMove) return
          this.clickMap(e.target, i)
        })
      }

      //绑定47个地图板块点击事件
      let temp1 = this.getMapObj.thumbtack

      for (let i = 1; i <= this.mapCount; i++) {
        // console.log(temp1["td" + i].tip, i)
        temp1["td" + i].tip.on("mousedown", e => {
          e.stopPropagation()
          this.$emit("click-thumbtack", i)
        })

        //设置手型
        temp1["td" + i].tip.cursor = "pointer"

      }
    },
    /**
     * 点击地图区块的事件
     * @param {Object} region 
     * @param {Object} idx 
     * @param {Object} isAnim 是否运行动画
     */
    clickMap(region, idx, isAnim = true) {
      if (!this.isRunEvent || idx == this.cueSelectIdx) return

      //没电亮的不让点击
      let tempFind = this.mapInfo.MapInfoList.find(e => (e.No == idx && e.isLight == "1"))

      if (tempFind === undefined) return

      this.isRunEvent = false
      this.cueSelectIdx = idx

      //隐藏其他的动画
      this.stopAnim()
      //隐藏所有选中区域
      // this.setBrAlpha()

      if (typeof idx == "number") idx = [idx]

      //寻找需要一起点亮的区块
      this.blockLight.forEach(e => {
        if (e.blockNum == this.cueSelectIdx && e.lightBlockId.length > 0) idx.push(...e.lightBlockId.split(","))
      })

      if (isAnim) {
        //移动到指定位置
        createjs.Tween.get(this.exportRoot).to(this.getPoint(region), 700).call(e => {
          this.isRunEvent = true
          //播放动画
          this.startAnim(idx)
        })
      } else {
        setTimeout(() => {

          this.isRunEvent = true
          //播放动画
          this.startAnim(idx)
        }, 350);
      }
    },

    /**
     * 缩放的时候要处理的xy偏移量 以屏幕中心偏移
     * 
     * 
     * @param {bool} isDefaultSet 是否默认设置偏移
     */
    scaleDeviation(oldVal, newVal, isDefaultSet = true) {

      let x, y;

      x = (this.getStageSize.w * oldVal - this.getStageSize.w * newVal) / 2
      y = (this.getStageSize.h * oldVal - this.getStageSize.h * newVal) / 2

      if (isDefaultSet) {
        this.exportRoot.x += x
        this.exportRoot.y += y
      }
      return { x: this.exportRoot.x + x, y: this.exportRoot.y + y }
    },

    /**
     * 计算需要移动的位置
     * @param {*} region 
     */
    getPoint(region) {

      let scaleY, scaleX, x, y

      //设置startScale是应为点击后 必定要设置到最大的缩放比
      this.startScale = scaleY = scaleX = this.getStageSize.maxScale

      //获取点击区域的尺寸
      let bounds = region.getBounds()
      //获取到区域相对于全局的xy
      let rPt = region.localToGlobal(bounds.width / 2, bounds.height / 2)

      //计算出当前缩放比到最大缩放比需要多少倍率
      let scale = this.getStageSize.maxScale / this.exportRoot.scale

      x = this.exportRoot.x * scale - (rPt.x * scale - this.getStageSize.centerX)
      y = this.exportRoot.y * scale - (rPt.y * scale - this.getStageSize.centerY)

      return { x, y, scaleX, scaleY }
    },

    /**
     * 获取在中心的区域 点亮它
     */
    getCenterRegion() {
      let brightRegion = this.getMapObj.brightRegion

      for (let i = 1; i <= this.mapCount; i++) {
        let temp = brightRegion["br" + i]
        let size = temp.getBounds()
        let pt = temp.localToGlobal(0, 0)
        // console.log(size.width)
        if (isCollision(this.getStageSize.centerX, this.getStageSize.centerY, pt.x, pt.y,
          size.width * this.exportRoot.scale, size.height * this.exportRoot.scale)) {
          this.clickMap(null, [i], false)
          break;
        }
      }


    },
    /**
     * 播放动画
     * @param {number | [1,2,3]} idx 
     */
    startAnim(idx) {
      let thumbtack = this.getMapObj.thumbtack
      let brightRegion = this.getMapObj.brightRegion

      //播放指定位置的动画
      if (typeof idx == "number") {
        thumbtack["td" + idx].visible = true
        thumbtack["td" + idx].gotoAndPlay('startAnim')

        //显示指定区域的透明度
        brightRegion["br" + idx].alpha = 1
      }
      else idx.forEach(e => {
        thumbtack["td" + e].visible = true
        thumbtack["td" + e].gotoAndPlay('startAnim')

        brightRegion["br" + e].alpha = 1
      })
    },
    /**
     * 停止动画
     * @param {number | [1,2,3]} idx 
     */
    stopAnim(idx, time = 300) {
      let temp = this.getMapObj.thumbtack

      //默认停止所有动画
      if (arguments.length < 1 || idx.length == 0) {
        for (let i = 1; i <= this.mapCount; i++) {
          createjs.Tween.get(temp["td" + i]).to({ alpha: 0 }, time).call(e => {
            temp["td" + i].alpha = 1
            temp["td" + i].visible = false
            temp["td" + i].gotoAndStop(0)
          })
        }
      } else if (idx == "number") {
        createjs.Tween.get(temp["td" + idx]).to({ alpha: 0 }, time).call(e => {
          temp["td" + idx].alpha = 1
          temp["td" + idx].visible = false
          temp["td" + idx].gotoAndStop(0)
        })

      } else {
        idx.forEach(e => {
          createjs.Tween.get(temp["td" + e]).to({ alpha: 0 }, time).call(d => {
            temp["td" + e].alpha = 1
            temp["td" + e].visible = false
            temp["td" + e].gotoAndStop(0)
          })

        })
      }
    },
    /**
     * 设置背景色的透明度
     * @param {number | [1,2,3]} idx 
     * @param {number} alpha 默认0
     */
    setBrAlpha(idx = [], alpha = 0.01) {

      //默认执行所有
      if (idx.length == 0)
        idx = (() => {
          let temp = []
          for (let i = 1; i <= this.mapCount; i++)temp.push(i)
          return temp
        })();

      let brightRegion = this.getMapObj.brightRegion

      idx.forEach(e => {
        brightRegion["br" + e].alpha = alpha
      })
    }
  },
  created() {
    request({
      url: "getBlockLight",
      method: "get",
    }).then((e) => {
      if (e.data) this.blockLight = e.data
    });
  },
  mounted() {

    let cfg = {
      fileName: "mobileMap.js",
      path: "./fla/mobileMap/",
      key: "2E6924DC7249474BBF0E62D1AF8C57CE"
    }

    if (!this.isMobile)
      cfg = {
        fileName: "pcMap.js",
        path: "./fla/pcMap/",
        key: "048BFCDA47B8F44A98B33E0268C68EEF"
      }

    createEase.loads.load(cfg.fileName, cfg.path, cfg.key).then(lib => {

      //实例化场景内容
      this.exportRoot = this.isMobile ? new lib.mobileMap() : new lib.pcMap();;


      this.stage = new createjs.Stage(this.$refs.mapCanvas);
      // this.stage.mouseEnabled = false
      this.stage.addChild(this.exportRoot);
      this.fnStartAnimation()
      this.stage.update()
      this.resize()

      //绑定手机版事件
      if (this.isMobile)
        // //绑定拖拽 缩放事件
        this.bindTouchEvent()
      else {
        //启用move 事件
        this.stage.enableMouseOver(10);
        this.bingPcEvent()
      }

    });

    if (this.isMobile) {
      touch.config.swipeMinDistance = 2
      touch.config.maxDoubleTapInterval = 10
    }

    // console.log(touch.config)
    this.$on("onresiz", e => {
      this.resize()
    })
    // window.onresize = this.resize
  },
  beforeDestroy() {
    // console.log(33)
    // window.onresize = undefined
    this.stage = undefined
    this.exportRoot = undefined

    //解除事件
    if (this.isMobile)
      touch.off(this.$refs.mapBox);
  }

};
</script>

<style scoped lang="less">
.box {
  position: relative;
  width: 100%;
  height: 100%;
  // overflow: hidden;

  .mapCanvas {
    position: relative;
    top: 0;
    left: 0;
  }
}
</style>